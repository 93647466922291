<template>
  <div class="swiper" @mouseover="overHd" @mouseout="auto">
    <div
      class="swiper-item dh-divshow"
      v-for="(item, ind) in list"
      :key="ind"
      v-show="ind == index"
    >
      <img :src="item" width="100%" />
    </div>
    <div class="thumbs">
      <span
        class="thumb"
        :class="{ active: item == index + 1 }"
        v-for="item in list.length"
        :key="item"
        @click="index = item - 1"
        >{{ item }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      //默认显示
      index: 0,
      stopID: null
    }
  },
  // 当组件挂载完毕 执行自动播放
  created () {
    this.auto()
  },
  methods: {
    //鼠标一开  auto自动执行
    auto () {
      let that = this
      that.stopID = setInterval(() => {
        that.index++
        that.check()
      }, 5000)
    },
    overHd () {
      clearInterval(this.stopID)
    },
    //检查边界
    check () {
      if (this.index == this.list.length) {
        this.index = 0
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.swiper {
  position: relative;
}
.swiper .thumbs {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.thumb {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 15px;
  text-indent: 9999em;
  margin: 0 5px;
  background-color: #fff;
}

.thumb:hover {
  cursor: pointer;
  background-color: #c2192e;
}

.active {
  background-color: #c2192e;
}
</style>
