<template>
  <div class="cy_index">
    <zCarousel
      :list="basicData.lbList"
      v-if="Object.keys(basicData).length != 0"
    ></zCarousel>
    <div class="cy_nav" v-if="Object.keys(basicData).length != 0">
      <div class="cy_nav_men">
        <h2
          class="public_title"
          :style="{
            backgroundImage: `url(${basicData.basicImg[0]})`
          }"
        >
          {{ basicData.basicTitle[0].key }}
        </h2>
        <div
          class="all_img_sp centerText"
          :style="{
            background: `url(${basicData.basicImg[1]}) center top / cover no-repeat`
          }"
        >
          <img :src="basicData.basicImg[2]" alt="" />
        </div>
      </div>
      <div class="cy_nav_js">
        <div class="js_top">
          <div class="js_top_pic centerText">
            <img :src="basicData.basicImg[3]" alt="" />
          </div>
          <div class="js_top_text">
            <h2
              class="public_title"
              :style="{
                backgroundImage: `url(${basicData.basicImg[0]})`
              }"
            >
              {{ basicData.basicTitle[0].value }}
            </h2>
            <img :src="basicData.basicImg[3]" alt="" style="display:none" />
            <p>{{ qyData.qy_text }}</p>
            <a class="all_more" href="">了解更多</a>
          </div>
        </div>
        <div class="js_num">
          <ul class="js_num_list">
            <li v-for="(item, idx) in qyData.qy_list" :key="idx">
              <span>{{ item.value }}</span>
              {{ item.key }}
            </li>
          </ul>
          <div class="js_num_cl">{{ qyData.qy_layout }}</div>
        </div>
      </div>
      <div
        class="all_img_sp centerText"
        :style="{
          background: `url(${basicData.basicImg[4]}) center top / cover no-repeat`
        }"
      >
        <img :src="basicData.basicImg[5]" alt="" />
        <a class="all_more" href="">了解更多</a>
      </div>
      <div class="cy_nav_new">
        <h2
          class="public_title"
          :style="{
            backgroundImage: `url(${basicData.basicImg[0]})`
          }"
        >
          {{ basicData.basicTitle[1].key }}
        </h2>
        <ul class="new_list">
          <li v-for="(item, idx) in newsList" :key="idx">
            <a
              :href="
                '#/cy_news?currentIdx=' + 0 + '&currentId=' + item.id
              "
              @click="toLink"
            >
              <img
                :src="item.cover_image"
                alt=""
                style="max-width:calc(100% + 30px);margin:-15px -15px 0 -15px"
              />
              <h4 class="two_ellipsis">{{ item.title }}</h4>
              <p class="two_ellipsis">{{ item.miaoshu }}</p>
            </a>
          </li>
        </ul>
      </div>
      <div class="cy_nav_map">
        <div class="map_img">
          <img :src="basicData.basicImg[6]" alt="" style="max-width:100%" />
          <p>{{ qyData.qy_layout }}</p>
        </div>
        <div class="map_text">
          <h2>{{ basicData.basicTitle[1].value }}</h2>
          <ul>
            <li v-for="(item, idx) in qyData.jmList" :key="idx">
              <img :src="item.value" alt="" />
              <p>{{ item.key }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBasicMsg, getPageMsg, getXwData } from '@/api/api'
import Carousel from '@/common/Carousel/zzCarousel.vue'
import zCarousel from '@/common/Carousel/zzCarousel1.vue'
export default {
  components: {
    Carousel,
    zCarousel
  },
  computed: {},
  data () {
    return {
      basicData: {},
      qyData: {},
      fwList: [],
      yzsList: [],
      active: 0,
      axList: [],
      newsList: []
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.basicData = res.data.data.basic
              that.qyData = res.data.data.企业介绍
            }
          })
          getXwData(project).then(res => {
            if (res.data.code == 1) {
              console.log(res.data.data)
              if (res.data.data.length != 0) {
                that.newsList = res.data.data[0].news
              }
            }
          })
        }
      })
    },
    toLink () {
      sessionStorage.setItem(this.changeData() + 'id', 122)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.cy_index {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  color: #212529;
  .cy_nav {
    .public_title {
      background-size: contain;
      background-position: 80px 0px;
      width: 296px;
      height: 139px;
      margin: 0 auto;
      font-size: 36px;
      line-height: 2.5;
      overflow: hidden;
      text-indent: 2em;
    }
    .all_more {
      width: 150px;
      line-height: 1.5;
      border-radius: 40px;
      text-align: center;
      background: #f9bd0d;
      font-size: 16px;
      display: inline-block;
      transition: all 0.4s;
      margin-top: 50px;
      color: #000;
    }
    .all_img_sp {
      width: 100%;
      height: 550px;
      margin-top: 50px;
      position: relative;
      flex-direction: column;
      img {
        position: relative;
        z-index: 10;
        transition: all 0.4s;
      }
      a {
        position: relative;
        z-index: 10;
        transition: all 0.4s;
      }
    }
    .all_img_sp:hover img {
      transform: translateY(-10px);
    }
    .all_img_sp::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
    }
    .all_more:hover {
      background: #e2a900;
      color: #fff;
    }
    .cy_nav_men {
      margin-top: 80px;
    }
    .cy_nav_js {
      width: 80%;
      padding: 80px 0;
      margin: 0 auto;
      .js_top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .js_top_pic {
          width: 42%;
          height: 500px;
          position: relative;
          img {
            width: 100%;
            position: relative;
            z-index: 10;
            box-shadow: 0 0 20px 0 rgba(0, 42, 97, 0.1);
          }
        }
        .js_top_pic::after {
          content: '';
          width: 38%;
          height: 100%;
          display: block;
          position: absolute;
          left: 13%;
          top: 0;
          background: #fabe0d;
        }
        .js_top_text {
          width: 51%;
          .public_title {
            margin: 50px 0 0 0;
            background-position: 0px;
            text-indent: 0;
          }
          p {
            text-indent: 2em;
            line-height: 2.5;
          }
        }
      }
      .js_num {
        margin-top: 80px;
        .js_num_list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          li {
            font-size: 14px;
            line-height: 40px;
            margin: 0 5%;
            span {
              font-size: 50px;
              margin-right: 5px;
              color: #f9bd0d;
            }
          }
        }
        .js_num_cl {
          font-size: 14px;
          line-height: 34px;
          margin-top: 40px;
          text-align: right;
          padding-right: 20%;
        }
      }
    }
    .cy_nav_new {
      width: 80%;
      padding: 80px 0;
      margin: 0 auto;
      .new_list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        li {
          width: calc(25% - 50px);
          margin: 0 10px;
          height: auto;
          background: #fff;
          overflow: hidden;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          transition: all 0.4s;
          cursor: pointer;
          padding: 15px;
          h4 {
            box-sizing: border-box;
            line-height: 1.5;
            padding-top: 15px;
          }
          h4::after {
            content: '';
            width: 28px;
            height: 3px;
            display: block;
            background: #f9bd0d;
            margin-top: 10px;
          }
          p {
            line-height: 1.5;
            padding-top: 15px;
            -webkit-line-clamp: 4;
          }
        }
        li:hover {
          background-color: #f9bd0d;
          color: #fff;
          h4::after {
            background: #fff;
          }
        }
      }
    }
    .cy_nav_map {
      width: 100%;
      background: #342c2a;
      padding: 90px 0 70px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      color: #fff;
      .map_img {
        width: 50%;
        text-align: center;
        line-height: 3;
      }
      .map_text {
        width: 30%;
        margin-right: 20%;
        h2 {
          font-size: 32px;
          line-height: 34px;
          margin-top: 45px;
          margin-bottom: 20px;
        }
        h2::after {
          content: '';
          width: 30px;
          height: 4px;
          display: block;
          background: #fff;
          margin-top: 15px;
        }
        ul {
          margin-top: 50px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            cursor: pointer;
            p {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
