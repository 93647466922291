var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cy_index"},[(Object.keys(_vm.basicData).length != 0)?_c('zCarousel',{attrs:{"list":_vm.basicData.lbList}}):_vm._e(),(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"cy_nav"},[_c('div',{staticClass:"cy_nav_men"},[_c('h2',{staticClass:"public_title",style:({
          backgroundImage: `url(${_vm.basicData.basicImg[0]})`
        })},[_vm._v(" "+_vm._s(_vm.basicData.basicTitle[0].key)+" ")]),_c('div',{staticClass:"all_img_sp centerText",style:({
          background: `url(${_vm.basicData.basicImg[1]}) center top / cover no-repeat`
        })},[_c('img',{attrs:{"src":_vm.basicData.basicImg[2],"alt":""}})])]),_c('div',{staticClass:"cy_nav_js"},[_c('div',{staticClass:"js_top"},[_c('div',{staticClass:"js_top_pic centerText"},[_c('img',{attrs:{"src":_vm.basicData.basicImg[3],"alt":""}})]),_c('div',{staticClass:"js_top_text"},[_c('h2',{staticClass:"public_title",style:({
              backgroundImage: `url(${_vm.basicData.basicImg[0]})`
            })},[_vm._v(" "+_vm._s(_vm.basicData.basicTitle[0].value)+" ")]),_c('img',{staticStyle:{"display":"none"},attrs:{"src":_vm.basicData.basicImg[3],"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.qyData.qy_text))]),_c('a',{staticClass:"all_more",attrs:{"href":""}},[_vm._v("了解更多")])])]),_c('div',{staticClass:"js_num"},[_c('ul',{staticClass:"js_num_list"},_vm._l((_vm.qyData.qy_list),function(item,idx){return _c('li',{key:idx},[_c('span',[_vm._v(_vm._s(item.value))]),_vm._v(" "+_vm._s(item.key)+" ")])}),0),_c('div',{staticClass:"js_num_cl"},[_vm._v(_vm._s(_vm.qyData.qy_layout))])])]),_c('div',{staticClass:"all_img_sp centerText",style:({
        background: `url(${_vm.basicData.basicImg[4]}) center top / cover no-repeat`
      })},[_c('img',{attrs:{"src":_vm.basicData.basicImg[5],"alt":""}}),_c('a',{staticClass:"all_more",attrs:{"href":""}},[_vm._v("了解更多")])]),_c('div',{staticClass:"cy_nav_new"},[_c('h2',{staticClass:"public_title",style:({
          backgroundImage: `url(${_vm.basicData.basicImg[0]})`
        })},[_vm._v(" "+_vm._s(_vm.basicData.basicTitle[1].key)+" ")]),_c('ul',{staticClass:"new_list"},_vm._l((_vm.newsList),function(item,idx){return _c('li',{key:idx},[_c('a',{attrs:{"href":'#/cy_news?currentIdx=' + 0 + '&currentId=' + item.id},on:{"click":_vm.toLink}},[_c('img',{staticStyle:{"max-width":"calc(100% + 30px)","margin":"-15px -15px 0 -15px"},attrs:{"src":item.cover_image,"alt":""}}),_c('h4',{staticClass:"two_ellipsis"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"two_ellipsis"},[_vm._v(_vm._s(item.miaoshu))])])])}),0)]),_c('div',{staticClass:"cy_nav_map"},[_c('div',{staticClass:"map_img"},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.basicData.basicImg[6],"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.qyData.qy_layout))])]),_c('div',{staticClass:"map_text"},[_c('h2',[_vm._v(_vm._s(_vm.basicData.basicTitle[1].value))]),_c('ul',_vm._l((_vm.qyData.jmList),function(item,idx){return _c('li',{key:idx},[_c('img',{attrs:{"src":item.value,"alt":""}}),_c('p',[_vm._v(_vm._s(item.key))])])}),0)])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }